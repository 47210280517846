import React, { createContext, useEffect, useContext, useState } from 'react';
import AuthContext from './AuthProvider';

const SplitContext = createContext();

const SplitProvider = ({ children }) => {
  // const auth = useContext(AuthContext);
  // const customerId = auth?.state?.customerId || 'anonymous';
  // const email = auth?.state?.email || '';
  // const [isEppoInitialized, setIsEppoInitialized] = useState(false);
  // const initializeEppo = async () => {
  //   if (isEppoInitialized) return;

  //   // Wait for Eppo SDK to load
  //   let attempts = 0;
  //   while (!window.eppo && attempts < 10) {
  //     await new Promise(resolve => setTimeout(resolve, 1000)); // Wait 500ms
  //     console.log('Waiting for Eppo SDK to load...', attempts);
  //     attempts++;
  //   }

  //   if (!window.eppo) {
  //     console.error('Eppo SDK still not loaded after retries');
  //     return;
  //   }
  //   await window.eppo
  //     .init({
  //       apiKey: process.env.GATSBY_EPPO_API_KEY
  //     })
  //     .then(() => {
  //       setIsEppoInitialized(true);
  //       const instance = window.eppo.getInstance();
  //       console.log('Getting eppo11:', instance);

  //       const flag = window.eppo
  //         .getInstance()
  //         .getJSONAssignment('petchemist---order-status', 0, { email }, false);
  //       console.log('Getting flag11:', flag);
  //     })
  //     .catch(() => {
  //       setIsEppoInitialized(false);
  //       console.warn('Failed to initialize Eppo');
  //     });
  //   console.log('Eppo SDK initialized');
  // };

  // const getEppoFlag = async (flagKey, defaultValue) => {
  //   if (!window.eppo) {
  //     console.error('Eppo SDK not loaded');
  //     return defaultValue;
  //   }

  //   if (!isEppoInitialized) {
  //     await initializeEppo();
  //   }
  //   console.log('Getting flag:', { flagKey, customerId, defaultValue });
  //   const instance = window.eppo.getInstance();
  //   console.log('Getting eppo:', instance);

  //   const flag = instance.getJSONAssignment(flagKey, customerId, { email }, defaultValue);
  //   return flag;
  // };
  // useEffect(() => {
  //   initializeEppo();
  // }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  return <SplitContext.Provider>{children}</SplitContext.Provider>;
};

export { SplitContext, SplitProvider };
